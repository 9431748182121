import styled from "styled-components";
import { NavLink } from 'react-router-dom';

/// icons
import dashboardIcon from './assets/nav/dashboard.svg'
import routerIcon from './assets/nav/router.svg'
import bridgeIcon from './assets/nav/bridge.svg'
import farmsIcon from './assets/nav/farms.svg'
import explorerIcon from './assets/nav/explorer.png'
//
import supportIcon from './assets/nav/support.svg'
import governanceIcon from './assets/nav/governance.svg'
import networkIcon from './assets/nav/network.svg'
import documentsIcon from './assets/nav/documents.svg'
import listingIcon from './assets/nav/listing.svg'
// socials
import telegramIcon from './assets/socials/telegram.svg'
import mediumIcon from './assets/socials/medium.svg'
import twitterIcon from './assets/socials/twitter.svg'
import githubIcon from './assets/socials/github.png'
import coinmarketcapIcon from './assets/socials/coinmarketcap.png'
import coingeckoIcon from './assets/socials/coingecko.png'

const StyledNav = styled.nav`
  display: grid;
  grid-row-gap: 8px;
  align-content: flex-start;
  height: 100vh;
  box-shadow: rgba(5, 6, 13, 0.24) 7px 2px 26px 0px;
  background: rgb(33, 38, 62);
  padding: 24px;
  padding-top: calc(70px + 36px);

  @media screen and (max-width: 700px){
    display: none;
  }
`;

const StyledNavLink = styled(NavLink)`
    display: grid;
    grid-template-columns: 38px 1fr;
    align-items: center;
    gap: 16px;
    align-items: center;
    cursor: pointer;
    padding: 4px 16px;
    border-radius: 8px;

    &, & *{
        text-decoration: none;
    }

    & span{
        display: flex;
        width: fit-content;
        padding: 10px;
        border-radius: 50%;
        background-color: rgb(54, 61, 95);

        img{
            filter: brightness(0);
            width: 18px;
            height: 18px;
        }
    }

    & font{
        display: flex;
        font-size: 14px;
        font-weight: 500;
        color: rgb(151, 157, 172);
        font-family: Manrope;
    }

    &:hover span img{
        filter: brightness(1);
    }
    &:hover font{
        text-shadow: 0px 0px 1px rgb(151, 157, 172);
    }

    &.active{
        background: linear-gradient(to right, rgb(115, 76, 226), rgb(96, 107, 251));    
        box-shadow: rgba(115, 75, 226, 0.51) 0px 0.25rem 0.75rem 0px;

        & span{
            background-color: rgb(33, 38, 62);
            box-shadow: rgba(115, 75, 226, 0.51) 0px 0.25rem 0.75rem 0px;

            img{
                filter: brightness(1);
            }
        }

        & font{
            color: white;
            font-weight: 600;
            text-shadow: 0px 0px 1px white;
        }
    }
`;

const StyledLink = styled.a`
    display: grid;
    grid-template-columns: 38px 1fr;
    align-items: center;
    gap: 16px;
    align-items: center;
    cursor: pointer;
    padding: 0px 16px;
    border-radius: 8px;

    &, & *{
        text-decoration: none;
    }

    & span{
        display: flex;
        width: fit-content;
        padding: 10px;

        img{
            filter: contrast(0) brightness(1);
            width: 18px;
            height: 18px;
        }
    }

    & font{
        display: flex;
        font-size: 0.75rem;
        font-weight: normal;
        color: rgb(150, 152, 158);
        font-family: Manrope;
    }

    &:hover{
        img{
            filter: contrast(0) brightness(1.75);
        }
        font{
            text-shadow: 0px 0px 1px rgb(150, 152, 158);
        }
    }
`;

const SublinkList = styled.div`
    display: grid;
    margin-top: 20px;
`;

const Socials = styled.div`
    display: grid;
    grid-template-columns: repeat(6, auto);
    justify-content: space-between;
    margin-top: 80px;
    padding: 0px 10px;
`;

const SocialLink = styled.a`
    display: flex;
    height: 34px;
    width: 34px;
    padding: 8px;
    border-radius: 100%;
    background-color: rgb(43, 49, 79);

    & img{
        width: 18px;
        height: 18px;
        filter: brightness(0);
        opacity: 0.3;
    }

    &:hover{
        background-color: rgb(95, 108, 252);

        img{
            filter: brightness(1);
            opacity: 1;
        }
    }
`;

const Powered = styled.div`
    display: grid;
    padding: 0px 10px;
    margin-top: 1rem;

    & span{
        color: white;

        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;

        b{
            font-weight: bold;
        }
    }

    & font{
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: rgb(150, 152, 158);
        margin-top: 6px;
    }
`;

const links = [
    {
        name: "Dashboard",
        src: dashboardIcon,
        to: '/dashboard'
    },
    {
        name: "Router",
        src: routerIcon,
        to: '/router'
    },
    {
        name: "Bridge",
        src: bridgeIcon,
        to: '/bridge'
    },
    {
        name: "Farms",
        src: farmsIcon,
        to: '/farms'
    },
    {
        name: "Explorer",
        src: explorerIcon,
        to: '/explorer'
    }
];

const sublinks = [
    {
        name: "Support",
        src: supportIcon,
        to: 'https://anyswaphelp.zendesk.com/hc/en-us'
    },
    {
        name: "Governance",
        src: governanceIcon,
        to: 'https://vote.anyswap.exchange'
    },
    {
        name: "Network",
        src: networkIcon,
        to: 'https://scan.multichain.org'
    },
    {
        name: "Documents",
        src: documentsIcon,
        to: 'https://docs.multichain.org'
    },
    {
        name: "Listing",
        src: listingIcon,
        to: 'https://dard6erxu8t.typeform.com/to/C7RwF08A'
    }
]

const socials = [
    {
        src: telegramIcon,
        to: 'https://t.me/anyswap'
    },
    {
        src: mediumIcon,
        to: 'https://medium.com/@anyswap'
    },
    {
        src: twitterIcon,
        to: 'https://twitter.com/AnyswapNetwork'
    },
    {
        src: githubIcon,
        to: 'https://github.com/anyswap'
    },
    {
        src: coinmarketcapIcon,
        to: 'https://coinmarketcap.com/currencies/anyswap/'
    },
    {
        src: coingeckoIcon,
        to: 'https://www.coingecko.com/en/coins/anyswap'
    },
]

export const Nav = () => {
    return <StyledNav>

        {
            links.map(el => <StyledNavLink to={el.to} key={el.to}>
                <span><img src={el.src} /></span>
                <font>{el.name}</font>
            </StyledNavLink>)
        }

        <SublinkList>
            {
                sublinks.map(el => <StyledLink href={el.to} key={el.to}>
                    <span><img src={el.src} /></span>
                    <font>{el.name}</font>
                </StyledLink>)
            }
        </SublinkList>

        <Socials>
            {socials.map(el => <SocialLink href={el.to} key={el.to}>
                <img src={el.src} />
            </SocialLink>)}
        </Socials>

        <Powered>
            <span>Powered by <b>Fusion DCRM</b></span>
            <font>© 2020 Anyswap. All rights reserved.</font>
        </Powered>
    </StyledNav>
}