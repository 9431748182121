import ethereum from '../tokens/ethereum.json';
import avalanche from '../tokens/avalanche.json';
import bnbChain from '../tokens/bnb-chain.json';
import cronos from '../tokens/cronos.json';
import arbitrum from '../tokens/arbitrum.json';
import fantom from '../tokens/fantom.json';
// import heco from '../tokens/heco.json';
// import kcc from '../tokens/kcc.json';
import moonbeam from '../tokens/moonbeam.json';
// import okc from '../tokens/okc.json';
import optimism from '../tokens/optimism.json';
import polygon from '../tokens/polygon.json';
import { networks } from '../../networks';

const obj = {
    [networks.Ethereum.chainId]: ethereum,
    [networks.Avalanche.chainId]: avalanche,
    [networks.Arbitrum.chainId]: arbitrum,
    [networks['BNB CHAIN'].chainId]: bnbChain,
    [networks.Fantom.chainId]: fantom,
    [networks.Polygon.chainId]: polygon,
    [networks.Moonbeam.chainId]: moonbeam,
    [networks.Optimism.chainId]: optimism,
    [networks.Cronos.chainId]: cronos,
    // "Okc": okc,
    // "Heco": heco,
    // "Kcc": kcc,
};

let newObj = {};

for(let key in obj){
    newObj[key] = [];

    obj[key].map(el => {
        const address = el.img.split('/').slice(-2)[0];
        if(!newObj[key]?.find(t => t.address === address)){
            newObj[key].push({...el, address});
        }
    })
}

export default newObj;