import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Select } from "./Select";
import { AppContext } from "../context";
import tokens from "../assets/tokens";
import { TokenModal } from "./TokenModal";
import { NetworkModal } from "./NetworkModal";
import { Web3Context } from "../web3context";

import erc20 from '../assets/contracts/erc20.abi.json';

const Container = styled.div`
  width: 100%;
  background-color: rgb(33, 38, 62);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.25rem 8px 0px;
  border-radius: 1.25rem;
  padding: 1.25rem 2.5rem;
`;

const Head = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  & span {
    font-weight: 500;
    font-size: 14px;
    color: rgb(195, 197, 203);
  }
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 50% 1fr 1fr;
  padding: 0.75rem 0rem;

  @media screen and (max-width: 700px){
    grid-template-columns: 1fr 1fr;
  }
`;

const Input = styled.input`
  color: rgb(255, 255, 255);
  font-weight: 500;
  outline: none;
  font-size: 44px;
  padding: 0px;
  height: 70px;
  border: none;
  background: none;
  border-bottom: 0.0625rem solid rgb(92, 103, 125);
  width: calc(100% - 16px);

  ${({disabled}) => disabled ? 'border-color: rgba(92, 103, 125, 0.5);' : ''}

  &:placeholder-shown {
    color: rgba(255, 255, 255, 0.9);
  }
`;

// const validate = (v) => /[0-9]{1,}(?:\.?[0-9]{1,})/.test(v);

// direction - from | to

const validate = (v) => {
  if (!/^[0-9\.]*$/.test(v)) return false;

  let newVal = v.replace(/^0+\./, "0.").replace(/^0(\d)/, "$1");

  newVal = /\d+\.\d*\./.test(newVal) ? false : newVal;
  newVal = /\d+\.?\d*/.test(newVal) || newVal === "" ? newVal : false;

  return newVal;
};

export const SwapSection = ({ direction = "from" }) => {
  const [tokenModal, setTokenModal] = useState(false);
  const [networkModal, setNetworkModal] = useState(false);

  const [balance, setBalance] = useState(null);

  const {getOutputAmount, web3} = useContext(Web3Context);
  const { from, to, setFrom, setTo, handleChangeNetwork, wallet } = useContext(AppContext);

  const setter = direction === "from" ? setFrom : setTo;

  const network = direction === "from" ? from.network : to.network;
  const token = direction === "from" ? from.token : to.token;

  const input = direction === "from" ? from.amount : to.amount;

  const handleChangeToken = (address) =>
    setter((el) => ({ ...el, token: address }));

    const handleInput = async (value) => {
      if(direction === "to") return;

      const v = validate(value);

      setter((prev) => (v === false ? prev : ({...prev, amount: v})));

      if(v){
        let amount = await getOutputAmount(from.network, from.token, v, to.network, to.token);
        amount = amount.toFixed(4);

        amount = parseFloat(amount);

        if(!parseFloat(amount)) amount = '0';

        setTo((prev) => ({...prev, amount}));
      }else{
        setTo((prev) => ({...prev, amount: ''}));
      }
    }

    useEffect(() => {
      if(direction === "to") return;

      (async () => {
        const v = from.amount;

        if(v){
          let amount = await getOutputAmount(from.network, from.token, v, to.network, to.token);
          amount = amount.toFixed(4);

          amount = parseFloat(amount);

          if(!parseFloat(amount)) amount = '0';

          setTo((prev) => ({...prev, amount}));
        }else{
          setTo((prev) => ({...prev, amount: ''}));
        }
      })();

    }, [from.network, from.token, to.network, to.token, from.amount]);

    useEffect(() => {
      if(direction === "from"){
        if(from.token.trim() && !/0x0{40}/.test(from.token.trim())){
          const tokenContract = new web3.eth.Contract(erc20, from.token.trim());

          web3.eth.getAccounts().then(([wallet]) => {
            tokenContract.methods.balanceOf(wallet).call().then(bal => {
              setBalance(web3.utils.fromWei(bal, 'ether'))
            });
          })
          
        }else{
          web3.eth.getAccounts().then(([wallet]) => {
            web3.eth.getBalance(wallet).then(bal => {
              setBalance(web3.utils.fromWei(bal, 'ether'))
            });
          })
        }
      }
    }, [from.network, from.token, wallet]);

  const isMobile = window.innerWidth <= 700;
  const inputJSX = <Input
    disabled={direction === "to"}
    type="text"
    placeholder="0.0"
    value={input}
    onChange={(e) => {
      handleInput(e.target.value)
    }}
    style={isMobile ? {
      gridColumnStart: 1,
      gridColumnEnd: 3
    }: null}
  />;

  return (
    <Container>
      <Head>
        <span>{direction[0].toUpperCase() + direction.slice(1)}</span>
        {Boolean(direction === "from" && balance) && <span>Balance {balance}</span>}
      </Head>
      <Body>
        {!isMobile && inputJSX}
        <Select
          address={token || "0"}
          setTokenModal={setTokenModal}
          network={network}
        />
        <Select network={network} setNetworkModal={setNetworkModal} />
        {isMobile && inputJSX}
      </Body>

      {tokenModal && (
        <TokenModal
          onClose={() => setTokenModal(null)}
          network={network}
          onChange={(data) => {
            handleChangeToken(data);
            setTokenModal(null);
          }}
        />
      )}

      {networkModal && (
        <NetworkModal
          network={network}
          onChange={(data) => {
            if(direction === "from"){
              handleChangeNetwork(data);
            }else{
              setter(prev => ({ ...prev, network: data }))
            }
            setNetworkModal(null);
          }}
          onClose={() => setNetworkModal(false)}
          exclude={direction === "from" ? to.network : from.network}
          setNetworkModal={setNetworkModal}
          hideDot={direction === "to"}
        />
      )}
    </Container>
  );
};

export const AnotherWallet = () => {
  const [another, setAnother] = useState('');

  return <Container>
  <Head>
    <span>Recipient</span>
  </Head>
  <Body style={{gridTemplateColumns: '1fr'}}>
    <Input
      style={{width: '100%', fontSize: 16, height: 40, fontWeight: 400}}
      type="text"
      placeholder="Wallet Address"
      value={another}
      onChange={(e) => {
        const v = e.target.value;
        if(v === '' || v == '0' || v == '0x' || /0x[0-9abcdefABCDEF]{0,40}/.test(v)){
          setAnother(prev => v);
        }
      }}
    />
    
  </Body>
</Container>
}