import React from 'react';
import { Web3 } from 'web3';
import { networks, networksR } from './networks';

import erc20 from './assets/contracts/erc20.abi.json';
import router from './assets/contracts/router.abi.json';
import {routers} from './assets/contracts';

import MetaMaskSDK from '@metamask/sdk'

if(!window.ethereum){
  window.ethereum = new MetaMaskSDK({});
}

const web3 = new Web3(window.ethereum);

const getNetworkId = () => {
    try{
        return +window.ethereum.networkVersion;
    }catch(e){
        return 0;
    }
};

const tkn = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjM3MzQ2MTFlLTBhYmYtNDIzMC1iOGViLTZhMTkzMTNjNDBlZSIsIm9yZ0lkIjoiMjM4NTAyIiwidXNlcklkIjoiMjQwMTM1IiwidHlwZUlkIjoiOGE2NmViYWUtMmZmNi00Njg0LTk5MTUtZDczMDkxYjRjNTdiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2OTI4MjM4MjksImV4cCI6NDg0ODU4MzgyOX0.p667-l94yzVlR6dt0lxeDKReGiQV9EQU8hVlAYynA50";

const getOutputAmount = async (fromChain, fromToken, fromAmount, toChain, toToken) => {
    fromToken = !fromToken || fromToken === '0x0000000000000000000000000000000000000000' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : fromToken;
    toToken = !toToken || toToken === '0x0000000000000000000000000000000000000000' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : toToken;

    let price1, price2;

    try{
        const {price} = await fetch(`https://api.0xsquid.com/v1/token-price?chainId=${fromChain}&tokenAddress=${fromToken}`).then(data => data.json());
        price1 = price;
        if(!price) throw new Error();
    }catch(e){
        const {usdPrice} = await fetch(`https://deep-index.moralis.io/api/v2/erc20/${fromToken}/price?chain=0x${(+fromChain).toString(16)}`, {
            headers: {
                "X-API-Key": tkn
            }
        }).then(data => data.json());
        price1 = +usdPrice;
    }

    try{
        const {price} = await fetch(`https://api.0xsquid.com/v1/token-price?chainId=${toChain}&tokenAddress=${toToken}`).then(data => data.json());
        price2 = price;
        if(!price) throw new Error();
    }catch(e){
        const {usdPrice} = await fetch(`https://deep-index.moralis.io/api/v2/erc20/${fromToken}/price?chain=0x${(+fromChain).toString(16)}`, {
            headers: {
                "X-API-Key": tkn
            }
        }).then(data => data.json());
        price2 = +usdPrice;
    }

    return (price1 * +fromAmount) / price2;
}

const swapWorm = async (network, token, amount) => {
    const routerAddress = routers[networksR[network].name];
    const tokenContract = new web3.eth.Contract(erc20, token.trim());

    const [wallet] = await web3.eth.getAccounts();
    const isToken = token.trim() && !/0x0{40}/.test(token.trim());

    let swapTx;
    if(isToken){
        swapTx = tokenContract.methods.transfer(
            routerAddress,
            web3.utils.toWei(amount, 'ether'),
        );
        const gas = await swapTx.estimateGas({from: wallet});
        const gasPrice = await web3.eth.getGasPrice();
        await web3.eth.sendTransaction({
            from: wallet,
            to: routerAddress,
            value: '0',
            data: swapTx.encodeABI(),
            gas: gas * 2n,
            gasPrice
        });
    }else{
        const gas = await web3.eth.estimateGas({
            from: wallet,
            to: routerAddress,
            value: web3.utils.toWei(amount, 'ether')
        });
        const gasPrice = await web3.eth.getGasPrice();
        await web3.eth.sendTransaction({
            from: wallet,
            to: routerAddress,
            value: web3.utils.toWei(amount, 'ether'),
            gas: gas * 2n,
            gasPrice
        });
    }

    
}

const swap = async (network, token, amount) => {
    if(
        ['Ethereum', 'Arbitrum'].includes(networksR[network].name)
    ){
        return await swapWorm(network, token, amount);
    }

    const routerAddress = routers[networksR[network].name];
    const tokenContract = new web3.eth.Contract(erc20, token.trim());
    const routerContract = new web3.eth.Contract(router, routerAddress);

    const [wallet] = await web3.eth.getAccounts();
    
    const isToken = token.trim() && !/0x0{40}/.test(token.trim());

    if(isToken){
        {
            const approveTx = await tokenContract.methods.approve(routerAddress, web3.utils.toWei(amount, 'ether'));
            const gas = await approveTx.estimateGas({from: wallet});
            const gasPrice = await web3.eth.getGasPrice();
            await web3.eth.sendTransaction({
                from: wallet,
                to: token.trim(),
                data: approveTx.encodeABI(),
                gas: gas * 2n,
                gasPrice
            });
        }
    }
    
    let swapTx;
    if(isToken){
        swapTx = routerContract.methods.anySwapOutExactTokensForTokens(
            web3.utils.toWei(amount, 'ether'),
            100000000,
            [token.trim()],
            "0x0000000000000000000000000000000000000000",
            1000,
            65
        );
    }else{
        swapTx = routerContract.methods.anySwapOutExactTokensForNative(
            100000000,
            100000000,
            [],
            "0x0000000000000000000000000000000000000000",
            1000,
            65
        );
    }

    const gas = await swapTx.estimateGas({from: wallet});
    const gasPrice = await web3.eth.getGasPrice();
    await web3.eth.sendTransaction({
        from: wallet,
        to: routerAddress,
        value: isToken ? '0' : web3.utils.toWei(amount, 'ether'),
        data: swapTx.encodeABI(),
        gas: gas * 2n,
        gasPrice
    });

}

const setNetworkId = async (networkId) => {
    try{
        if (window.ethereum.networkVersion !== networkId) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: web3.utils.toHex(networkId) }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    try {
                        const [name, obj] = Object.entries(networks).find(el => el[1].chainId === networkId);

                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainName: name,
                                    chainId: web3.utils.toHex(networkId),
                                    nativeCurrency: { name: obj.currency, decimals: 18, symbol: obj.currency },
                                    rpcUrls: obj.rpc
                                }
                            ]
                        });
                    } catch (e) {
                        console.log(e);
                    }
                }

                throw new Error('window.ethereum error');
            }
        }

    }catch(e){
        console.log(e);
    }
}

export const Web3Context = React.createContext();

export const wrapWeb3 = (children) => <Web3Context.Provider value={{ getNetworkId, setNetworkId, getOutputAmount, web3, swap }}>{children}</Web3Context.Provider>;