import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { SwapSection, AnotherWallet } from "../components/SwapSection";

import { ReactComponent as ArrowIcon } from "../assets/arrow.svg";
import reminderIcon from "../assets/reminder.svg";

import routerIcon from "../assets/router.svg";
import poolIcon from "../assets/pool.svg";

import { AppContext } from "../context";
import { Web3Context } from "../web3context";
import { useMemo } from "react";

import tokens from "../assets/tokens";
import { networksR, networks } from "../networks";

import poolJson from '../assets/pool.json';

const Container = styled.div`
  padding: 70px;
  padding-top: calc(70px + 24px);

  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
`;

const Head = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  height: 38px;
  width: 100%;
  align-items: center;
  margin: 16px 0px;
`;

const Tabs = styled.div`
  background-color: rgb(33, 38, 62);
  border-radius: 7px;
  display: grid;
  grid-template-columns: auto auto;

  cursor: pointer;

  & > span {
    padding: 4px 8px;
    color: white;
    display: flex;
    align-items: center;

    font-size: 0.75rem;
    font-weight: 500;

    span {
      background-color: rgb(245, 245, 245);
      border-radius: 100%;
      margin-right: 0.625rem;
      width: 28px;
      height: 28px;
      padding: 7px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  & > span:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  & > span:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  ${({ last }) => "& > span:" + (last ? "last" : "first")}-child {
    background-color: rgb(103, 37, 252);

    span {
      background-color: rgb(115, 75, 226);

      img {
        filter: contrast(0) brightness(2);
      }
    }
  }
`;

const Title = styled.span`
  font-size: 24px;
  font-weight: 800;
  color: rgb(255, 255, 255);
`;

const Body = styled.div`
  display: grid;
  row-gap: 8px;
`;

const Between = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  justify-items: center;
  align-content: center;

  & span {
    justify-self: flex-end;
    font-size: 12px;
    color: white;
  }
`;

const Arrow = styled(ArrowIcon)`
  cursor: pointer;
`;

const Reminder = styled.div`
  border-radius: 0.5625rem;
  border: 0.5px solid rgb(43, 49, 79);
  background-color: rgb(43, 49, 79);
  padding: 1rem 1.25rem;
  margin-top: 0.625rem;

  & b {
    font-size: 12px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    font-weight: bold;
    line-height: 1.5;

    img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }

  & ul {
    color: rgb(255, 255, 255);
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 0px;

    li {
      font-weight: 500;
      line-height: 1.83;
      font-size: 12px;
    }
  }
`;

const SwapButton = styled.button`
  border: none;
  outline: none;

  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  background-color: rgb(95, 107, 251);
  border: 1px solid rgb(95, 107, 251);
  color: rgb(255, 255, 255);

  font-size: 16px;
  font-family: Manrope, sans-serif !important;
  cursor: pointer;

  width: 300px;
  text-align: center;

  justify-self: center;
  margin-top: 10px;

  ${({disabled}) => disabled ? 'opacity: 0.3;' : ''}
`;

const Swap = ({last,setLast}) => {
  const { from, to, setFrom, setTo, connectJsx, wallet } = useContext(AppContext);
  const { setNetworkId, swap } = useContext(Web3Context);

  const handleReverse = useCallback(() => {
    setNetworkId(to.network)
      .then(() => {
        setFrom(() => to);
        setTo(() => from);
      })
      .catch((e) => null);
  }, [from, to, setTo, setFrom]);

  const swapActive = +from.amount && +to.amount;

  
  const handleSwap = async () => {
    try{
      await swap(from.network, from.token, from.amount);

      setFrom(prev => ({...prev, amount: '0'}));
      setTo(prev => ({...prev, amount: '0'}));
    }catch(e){}
  }


  const currency = useMemo(() => {
    const val = from.token.trim() && !/0x0{40}/.test(from.token.trim())
    ? tokens[from.network]?.find((el) => el.address === from.token) || tokens[from.network][0]
    : networksR[from.network];

    return val?.symbol || val?.currency;
  }, [from.network, from.token]);


  
  const [showAnother, setShowAnother] = useState(false);

  return (
    <Container>
      <Head>
        <Title>Router</Title>
        <Tabs last={last}>
          <span onClick={() => setLast(false)}>
            <span>
              <img src={routerIcon} />
            </span>
            Router
          </span>
          <span onClick={() => setLast(true)}>
            <span>
              <img src={poolIcon} />
            </span>
            Pool
          </span>
        </Tabs>
      </Head>

      <Body>
        <SwapSection direction="from" />
        <Between>
          <span />
          <Arrow onClick={handleReverse} />
          <span style={{cursor: 'pointer'}} onClick={() => setShowAnother(prev => !prev)}>+ Send To</span>
        </Between>
        <SwapSection direction="to" />

        {showAnother ?
          <AnotherWallet />
        : null}

        <Reminder>
          <b>
            <img src={reminderIcon} />
            Reminder:
          </b>
          <ul>
            <li>Crosschain Fee is 0.00 %, Gas fee 0.000121 {currency}</li>
            <li>Minimum Crosschain Amount is 0.007619 {currency}</li>
            <li>Maximum Crosschain Amount is 3,174.6 {currency}</li>
            <li>Estimated Time of Crosschain Arrival is 10-30 min</li>
            <li>
              Crosschain amount larger than 634.92 {currency} could take up to 12 hours
            </li>
          </ul>
        </Reminder>

        {wallet ?
          <SwapButton
            disabled={!swapActive}
            onClick={handleSwap}>Swap</SwapButton>
        : connectJsx}
      </Body>
    </Container>
  );
}

const chains = {
  "ETH": networks.Ethereum.icon,
  "BNB": networks["BNB CHAIN"].icon,
  "MATIC": networks.Polygon.icon,
  "FTM": networks.Fantom.icon,
  'AVAX': networks.Avalanche.icon,
  "CRO": networks.Cronos.icon, 
}

const PoolTable = styled.div`
  width: 100%;
  max-width: 100%;
  display: grid;
  overflow-y: scroll;
  max-height: 70vh;

  border-radius: 0.5625rem;
    box-shadow: rgba(0, 0, 0, 0.06) 0.4375rem 0.125rem 1.625rem 0px;
    background-color: rgb(33, 38, 62);
    padding: 1rem 2.5rem;
    margin-bottom: 20px;
    gap: 8px;

  & > div{
    display: grid;
    grid-template-columns: 25% 45% 1fr;
    grid-template-rows: auto;
    align-content: center;

    &:first-child span{
      display: flex;
      color: rgb(255, 255, 255);
      padding: 12px 8px;
      white-space: nowrap;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.5;
    }

    &:not(:first-child){
      box-shadow: rgba(0, 0, 0, 0.4) 0px 0.125rem 0.25rem 0px;

      span{
        padding: 8px;
        display: flex;
        gap: 8px;

        font-size: 1rem;
        line-height: 1.25;
        color: rgb(255, 255, 255);
      }
    }

    span{
      display: flex;

      &:last-child{
        justify-self: flex-end;
      }

      align-items: center;

      img{
        height: 36px;
        width: 36px;
        background: white;
        padding: 6px;
        border-radius: 100%;
      }

      &.chains img{
        padding: 0px;
        height: 24px;
        width: 24px;
      }
    }
  }
`;

const Pool = ({last, setLast}) => {
  return  <Container>
  <Head>
    <Title>Pool</Title>
    <Tabs last={last}>
      <span onClick={() => setLast(false)}>
        <span>
          <img src={routerIcon} />
        </span>
        Router
      </span>
      <span onClick={() => setLast(true)}>
        <span>
          <img src={poolIcon} />
        </span>
        Pool
      </span>
    </Tabs>
  </Head>

  <Body>
    <PoolTable>
      <div>
          <span>
            Tokens
            </span>
          <span>
            Chains
            </span>
          <span>
            Liquidity
          </span>
      </div>
      {poolJson.map(el => <div>
        <span><img src={el.img}/> {el.symbol}</span>
        <span className="chains">{el.chains.map(n => chains[n] ? <img src={chains[n]} />: null)}</span>
        <span>{el.liq}</span>
      </div>)}
      
    </PoolTable>
  </Body>
</Container>
}

export const Router = () => {
  const [last, setLast] = useState(false);

  return !last ? <Swap last={last} setLast={setLast} /> : <Pool last={last} setLast={setLast} />
};
