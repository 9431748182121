import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../assets/select-arrow.svg";
import { useContext } from "react";
import { AppContext } from "../context";

import networks from "../assets/networks";
import tokens from "../assets/tokens";
import { networksR } from "../networks";

const Container = styled.div`
  color: rgb(151, 157, 172);
  font-size: 1rem;
  height: 70px;
  border: 0.0625rem solid rgb(54, 61, 95);
  border-radius: 0.75rem;
  background-color: rgb(43, 49, 79);
  outline: none;
  cursor: pointer;
  user-select: none;
  padding: 0px 1.25rem;
  margin: 0px 5px;
  align-content: center;
  margin: 0px 5px;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  align-items: center;

  grid-column-gap: 8px;

  & > span {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  ${({ bold }) =>
    bold &&
    `
    & b{
      font-weight: bold !important;
      color: white !important;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    & span{
      grid-row-start: 2;
      grid-row-end: 3;
      color: white;
      font-size: 0.75rem;
    }
  `}
`;

const Icon = styled.img`
  width: 46px;
  height: 46px;
  background-color: white;
  border-radius: 50%;
  padding: 10px;

  grid-row-start: 1;
  grid-row-end: 3;
`;

const Arrow = styled(ArrowIcon)`
  border-radius: 100%;
  width: 1.625rem;
  height: 1.625rem;
  background: rgb(33, 38, 62);
  padding: 6px;

  grid-row-start: 1;
  grid-row-end: 3;
`;

export const Select = ({
  setTokenModal,
  network,
  address,
  setNetworkModal,
}) => {
  if (!network) return null;

  const isToken = Boolean(address);

  const val = isToken
    ? tokens[network].find((el) => el.address === address) || tokens[network][0]
    : networksR[network];

  return (
    <Container
      bold={isToken}
      onClick={
        isToken ? () => setTokenModal(true) : () => setNetworkModal(true)
      }
    >
      {isToken ? (
        <>
          <Icon src={val.img} />
          <b>{val.symbol}</b>
          <span>{val.name}</span>
        </>
      ) : (
        <>
          <Icon src={val.icon} />
          <span>{val.name} mainnet</span>
        </>
      )}
      <Arrow />
    </Container>
  );
};
