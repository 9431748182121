import styled from "styled-components";
import logoSrc from './assets/logo.svg'
import logoutSrc from './assets/logout.svg'

import ethereumIcon from './assets/networks/ethereum.svg';
import { Nav } from "./Nav";
import { BrowserRouter as ReactRouter, Route, Switch, Redirect } from "react-router-dom";
import { Router } from './pages/Router';
import { NetworkModal } from "./components/NetworkModal";
import { memo, useContext, useEffect, useMemo, useState } from "react";

import { networks, networksR } from "./networks";
import networkIcons from './assets/networks'
import { AppContext } from "./context";
import { TokenModal } from "./components/TokenModal";
import { Web3Context, wrapWeb3 } from "./web3context";
import MetaMaskSDK from '@metamask/sdk'

if(!window.ethereum){
  window.ethereum = new MetaMaskSDK({});
}


const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #151a2f;
  height: 100%;
`;

const StyledHeader = styled.header`
  display: flex;
  flex-flow: wrap;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: rgba(5, 6, 13, 0.24) 7px 2px 26px 0px;
  background: rgb(33, 38, 62);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;

  & > *{
    display: grid;
    grid-template-columns: 1fr auto auto;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    top: 0px;
    position: relative;
    padding: 0.875rem 1rem;
    z-index: 2;
    height: 70px;
    max-width: 1440px;
    gap: 8px;
  }
`;

const StyledButton = styled.button`
  border: none;
  outline: none;
  display: grid;
  grid-template-columns: 20px auto;
  grid-template-rows: 20px;

  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 8px 12px;
  background-color: rgba(243, 132, 30, 0.05);
  color: rgb(255, 255, 255);
  gap: 5px;

  font-size: 16px;
  font-family: Manrope, sans-serif !important;
  cursor: pointer;

  & > img{
    border-radius: 50%;
    height: 20px;
  }
`;

const StyledLogout = styled.img`
  width: 16px;
  height: 16px;
`;

const NetworkButton = ({ networkId, onClick }) => {

  const { img, name } = useMemo(() => {

    if (networkId) {
      const { name, icon } = networksR[networkId];

      return {
        name,
        img: icon
      }
    }

    return {};
  }, [networkId]);


  return networkId ? <StyledButton onClick={onClick}>
    <img src={img} alt={name} />
    {name}
  </StyledButton> : null;
}

const StyledConnectButton = styled.button`
  border: none;
  outline: none;

  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  background-color: rgb(95, 107, 251);
  border: 1px solid rgb(95, 107, 251);
  color: rgb(255, 255, 255);

  font-size: 16px;
  font-family: Manrope, sans-serif !important;
  cursor: pointer;
`;

const WalletAddr = styled.div`
  border: none;
  outline: none;

  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  border: 1px solid transparent;
  color: rgb(255, 255, 255);

  font-size: 16px;
  font-family: Manrope, sans-serif !important;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  & > *{
    display: grid;
    grid-template-columns: 320px 1fr;
    max-width: 1440px;
    width: 100%;

    @media screen and (max-width: 700px){
      grid-template-columns:  1fr;
    }
  }
`;

function App() {
  const { setNetworkId, getNetworkId } = useContext(Web3Context)

  const [wallet, setWallet] = useState(null);

  const requestWallet = async () => {
    if(window.ethereum) {
      await window.ethereum.enable();
    }
    try{
      const accs = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setWallet(accs[0])
    }catch(e){}
  }

  const [from, setFrom] = useState({
    network: networks.Ethereum.chainId,
    token: '0x0000000000000000000000000000000000000000',
    amount: ''
  });

  const [to, setTo] = useState({
    network: networks["BNB CHAIN"].chainId,
    token: "0x0000000000000000000000000000000000000000",
    amount: ''
  });

  useEffect(() => {
    setFrom(prev => ({ ...prev, token: '' }))
  }, [from.network])

  useEffect(() => {
    setTo(prev => ({ ...prev, token: '' }))
  }, [to.network])

  const [networkModal, setNetworkModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      requestWallet();
      const networkId = getNetworkId();
      setFrom((prev) => ({ ...prev, network: networkId }))
    }, 1000);

    window.ethereum.on('accountsChanged', requestWallet);
  }, []);

  const handleChangeNetwork = async (chainId) => {
    try{
      await setNetworkId(chainId)

      setFrom(prev => ({ ...prev, network: chainId }))
    }catch(e){
      console.log("err", e)
    }  
  };

  useEffect(() => {

    const i = setInterval(() => {
      const chainId = getNetworkId();

      if (chainId !== from.network) {
        setFrom((prev) => ({ ...prev, network: chainId }))
      }

    }, 1000);

    return () => clearInterval(i);
  }, [])

  useEffect(() => {
    if (from.network === to.network) {
      const secondChain = from.network === networks.Ethereum.chainId ? networks["BNB CHAIN"].chainId : networks.Ethereum.chainId;
      setTo((prev) => ({ ...prev, network: secondChain }))
    }
  }, [from.network, to.network])

  return (
    <AppContext.Provider value={{ from, setFrom, to, setTo, handleChangeNetwork, wallet, connectJsx: <StyledConnectButton onClick={requestWallet}>Connect to a wallet</StyledConnectButton> }}>
      <ReactRouter>
        <StyledApp>
          <StyledHeader>
            <div>
              <img height={42} src={logoSrc} />
              <NetworkButton networkId={from.network} onClick={() => setNetworkModal(true)} />
              {wallet ? <WalletAddr>{wallet.slice(0, 6) + '..' + wallet.slice(-4)}</WalletAddr> : <StyledConnectButton onClick={requestWallet}>Connect to a wallet</StyledConnectButton>}
            </div>
          </StyledHeader>
          <StyledBody>
            <div>
              <Nav />
              <Switch>
                <Route exact path="/" component={() => <Redirect to="/router" />} />
                <Route path="/router" component={Router} />

                <Route path="" component={() => <Redirect to="/router" />} />
              </Switch>
            </div>
          </StyledBody>
        </StyledApp>

        {networkModal && <NetworkModal onChange={(chainId) => {
          handleChangeNetwork(chainId)
          setNetworkModal(false);
        }} network={from.network} onClose={() => setNetworkModal(false)} />}
      </ReactRouter>
    </AppContext.Provider>
  );
}

export default () => wrapWeb3(<App />);
