import arbitrum from "./arbitrum.png";
import celo from "./celo.png";
import fuse from "./fuse.png";
import iotex from "./iotex.png";
import okc from "./okc.png";
import velas from "./velas.png";
import astar from "./astar.png";
import cloverParachain from "./clover-parachain.png";
import fusion from "./fusion.svg";
import kcc from "./kcc.svg";
import optimism from "./optimism.png";
import aurora from "./aurora.png";
import cronos from "./cronos.png";
import gnosis from "./gnosis.png";
import metis from "./metis.png";
import polygon from "./polygon.png";
import avalanche from "./avalanche.png";
import darwiniaCrabSmart from "./darwinia-crab-smart.png";
import harmony from "./harmony.png";
import moonbeam from "./moonbeam.png";
import shidenNetwork from "./shiden-network.png";
import bnbChain from "./bnb-chain.svg";
import ethereum from "./ethereum.svg";
import heco from "./heco.png";
import moonriver from "./moonriver.png";
import syscoin from "./syscoin.png";
import boba from "./boba.png";
import fantom from "./fantom.png";
import oasisNetwork from "./oasis-network.png";
import telos from "./telos.png";

export default [
    ["Ethereum", ethereum],
    ["Avalanche", avalanche],
    ["Arbitrum", arbitrum],
    ["BNB CHAIN", bnbChain],
    ["Fantom", fantom],
    ["Polygon", polygon],
    ["Moonbeam", moonbeam],
    // ["Moonriver", moonriver],
    // ["Harmony", harmony],

    
    ["Optimism", optimism],
    // ["Aurora", aurora],
    // ["Boba", boba],


    
    ["Cronos", cronos],
    
    
    
    
    
    
    // ["Okc", okc],
    // ["Heco", heco],
    // ["Gnosis", gnosis],
    // ["Celo", celo],
    // ["Kcc", kcc],
    // ["Fusion", fusion],
    // ["Metis", metis],
    // ["Telos", telos],
    // ["Oasis Network", oasisNetwork],
    // ["Syscoin", syscoin],
    // ["IoTeX", iotex],
    // ["Shiden Network", shidenNetwork],
    // ["Fuse", fuse],
    // ["Astar", astar],
    // ["Velas", velas],
    // ["Clover Parachain", cloverParachain],
    // ["Darwinia Crab smart", darwiniaCrabSmart],
]