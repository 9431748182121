import styled from "styled-components";
import tokens from "../assets/tokens";
import { useRef, useState } from "react";

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.424);
  top: 0px;
  left: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 100;
`;

const Body = styled.div`
  background-color: rgb(33, 36, 41);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px 0px;
  padding: 0px;
  width: 420px;
  height: 80vh;
  overflow: hidden;
  align-self: center;
  border-radius: 20px;
`;

const Header = styled.div`
  display: grid;
  grid-auto-rows: auto;
  row-gap: 14px;
  padding: 20px 20px 12px;

  & > span {
    box-sizing: border-box;
    margin: 0px;
    font-weight: 500;
    font-size: 16px;
    color: white;
  }

  & > input {
    display: flex;
    padding: 16px;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    background: none;
    outline: none;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(64, 68, 79);
    appearance: none;
    font-size: 18px;
    transition: border 100ms ease 0s;
  }
`;

const List = styled.div`
  display: grid;
  width: 100%;
  overflow-x: auto;
  height: calc(80vh - 123px);
  align-content: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    outline: none;
    border-image: initial;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 20px;
    height: 56px;

    &:hover {
      background-color: rgb(21, 26, 47);
    }

    span {
      display: flex;

      img {
        background-color: white;

        border-radius: 100%;
        width: 26px;
        height: 26px;
      }
    }

    font {
      display: flex;
      flex-flow: row;
      font-size: 1rem;
      font-family: Manrope;
      font-weight: 500;
      color: white;
    }

    i {
      display: flex;
      font-style: normal;
      color: white;
      font-size: 10px;
    }
  }
`;

export const TokenModal = ({ onClose, network, onChange }) => {
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current) {
      if (e.target == ref.current) {
        onClose();
      }
    }
  };

  const [substring, setSubstring] = useState("");

  const _tokens = tokens[network];

  return (
    <Backdrop ref={ref} onClick={handleClick}>
      <Body>
        <Header>
          <span>Select a token</span>
          <input
            type="text"
            placeholder="Select name or paste address"
            onChange={(e) => {
              setSubstring(e.target.value);
            }}
          />
        </Header>
        <List>
          {_tokens
            .filter((el) =>
              el.name.toLowerCase().includes(substring.toLocaleLowerCase())
            )
            .map((el, i) => (
              <div key={el.address} onClick={() => onChange(el.address)}>
                <span>
                  <img src={el.img} />
                </span>
                <div>
                  <font>{el.symbol}</font>
                  <i>{el.name}</i>
                </div>
              </div>
            ))}
        </List>
      </Body>
    </Backdrop>
  );
};
