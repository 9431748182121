import networkIcons from "./assets/networks";

const networks = {
    "Ethereum": {
        chainId: 1,
        currency: "ETH",
        rpc: [
            "https://eth.llamarpc.com",
            "https://uk.rpc.blxrbdn.com",
            "https://eth.rpc.blxrbdn.com",
            "https://rpc.ankr.com/eth"
        ]
    },
    "Avalanche": {
        chainId: 43114,
        currency: "AVAX",
        rpc: [
            "https://avalanche.blockpi.network/v1/rpc/public",
            "https://api.avax.network/ext/bc/C/rpc",
            "https://avax.meowrpc.com",
            "https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc"
        ]
    },
    "Arbitrum": {
        chainId: 42161,
        currency: "ETH",
        rpc: [
            "https://arbitrum.llamarpc.com",
            "https://arb-mainnet-public.unifra.io",
            "https://arbitrum-one.publicnode.com",
            "https://1rpc.io/arb"
        ]
    },
    "BNB CHAIN": {
        chainId: 56,
        currency: "BNB",
        rpc: [
            "https://bsc-dataseed1.binance.org",
            "https://bsc-dataseed1.ninicoin.io",
            "https://rpc.ankr.com/bsc",
            "https://binance.llamarpc.com",
        ]
    },
    "Fantom": {
        chainId: 250,
        currency: "FTM",
        rpc: [
            "https://fantom.blockpi.network/v1/rpc/public",
            "https://rpcapi.fantom.network",
            "https://rpc.ankr.com/fantom",
            "https://fantom.publicnode.com"
        ]
    },
    "Polygon": {
        chainId: 137,
        currency: "MATIC",
        rpc: [
            "https://polygon.llamarpc.com",
            "https://polygon.blockpi.network/v1/rpc/public",
            "https://poly-rpc.gateway.pokt.network",
            "https://polygon-rpc.com"
        ]
    },
    "Moonbeam": {
        chainId: 1284,
        currency: "GLMR",
        rpc: [
            "https://moonbeam-rpc.dwellir.com",
            "https://moonbeam.public.blastapi.io",
            "https://endpoints.omniatech.io/v1/moonbeam/mainnet/public",
            "https://rpc.api.moonbeam.network"
        ]
    },
    "Optimism": {
        chainId: 10,
        currency: "ETH",
        rpc: [
            "https://optimism.llamarpc.com",
            "https://optimism.meowrpc.com",
            "https://optimism.drpc.org",
            "https://optimism.publicnode.com"
        ]
    },
    "Cronos": {
        chainId: 25,
        currency: "CRO",
        rpc: [
            "https://cronos.blockpi.network/v1/rpc/public",
            "https://cronos-evm.publicnode.com",
            "https://evm.cronos.org",
        ]
    }
}

const networksR = {};

networkIcons.forEach(([name, icon]) => {
    networks[name].icon = icon;

    networksR[networks[name].chainId] = { ...networks[name], name }
})


export { networks, networksR };