import styled from "styled-components";

import { useRef, useState } from "react";
import { networks } from "../networks";

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.424);
  top: 0px;
  left: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 100;
`;

const Body = styled.div`
  background-color: rgb(33, 36, 41);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px 0px;
  padding: 0px;
  width: 420px;
  height: 80vh;
  max-height: 700px;
  align-self: center;
  border-radius: 20px;
`;

const Header = styled.div`
  display: grid;
  grid-auto-rows: auto;
  row-gap: 14px;
  padding: 20px 20px 12px;

  & > span {
    box-sizing: border-box;
    margin: 0px;
    font-weight: 500;
    font-size: 16px;
    color: white;
  }

  & > input {
    display: flex;
    padding: 16px;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    background: none;
    outline: none;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(64, 68, 79);
    appearance: none;
    font-size: 18px;
    transition: border 100ms ease 0s;
  }
`;

const List = styled.div`
  display: grid;
  width: 100%;
  overflow-x: auto;
  height: calc(80vh - 123px);
  align-content: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    background-color: rgb(33, 38, 62);
    outline: none;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: transparent transparent rgb(95, 95, 95);
    border-image: initial;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 20px;

    &:hover {
      background-color: rgb(54, 61, 95);
    }

    span {
      display: flex;
      padding: 10px;
      border-radius: 100%;
      background-color: white;

      img {
        width: 26px;
        height: 26px;
      }
    }

    font {
      display: flex;
      flex-flow: row;
      font-size: 1rem;
      font-family: Manrope;
      font-weight: 500;
      color: rgb(151, 157, 172);

      i {
        display: flex;
        align-items: center;
        margin-right: 10px;

        &::before {
          content: "";
          background-color: rgb(39, 174, 96);
          width: 8px;
          height: 8px;
          border-radius: 100%;
        }
      }
    }
  }
`;

export const NetworkModal = (props) => {
  const { onClose, network, onChange, exclude, hideDot } = props;

  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current) {
      if (e.target == ref.current) {
        onClose();
      }
    }
  };

  const [substring, setSubstring] = useState("");

  const networksArr = Object.entries(networks).filter((el) => !parseInt(el[0])).filter(el => 
    !['Moonbeam', 'Optimism', 'Cronos'].includes(el[0])
  );

  return (
    <Backdrop ref={ref} onClick={handleClick}>
      <Body>
        <Header>
          <span>Switch to</span>
          <input
            type="text"
            placeholder="Switch to"
            value={substring}
            onChange={(e) => {
              setSubstring(e.target.value);
            }}
          />
        </Header>
        <List>
          {networksArr
            .filter((el) => el[1].chainId !== exclude)
            .filter((el) =>
              el[0].toLowerCase().includes(substring.toLocaleLowerCase())
            )
            .map((el, i) => (
              <div key={el[0]} onClick={() => onChange(el[1].chainId)}>
                <span>
                  <img src={el[1].icon} />
                </span>
                <font>
                  {network == el[0] && !hideDot ? <i></i> : null}
                  {el[0]} mainnet
                </font>
              </div>
            ))}
        </List>
      </Body>
    </Backdrop>
  );
};
